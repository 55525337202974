import React from 'react';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import SliderContent from '../../components/GSTTemp/SliderContent';
import Cta from '../../components/Cta';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function ProfessionalAdvisory() {
  /* Slider */
  // const ProAdvSlider = [
  //   {
  //     content_color: false,
  //     header_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Professional Advisory Services For Income Tax`,
  //     content: `Online Professional Advisory Services For Income Tax Filing.`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag: "Best Professional Advisory Services in Hosur",
  //   },
  //   {
  //     content_color: false,
  //     header_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Professional Advisory Services for GST`,
  //     content: `Professional Advisory Services For GST Filing`,
  //     image: "/imgs/business/sliders/pvt-slide.png",
  //     alt_tag:
  //       "Best Online Professional Advisory Services For Tax near Bangalore",
  //   },
  //   {
  //     content_color: false,
  //     header_color: false,
  //     marginTop: 60,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Professional Tax Advisory Services`,
  //     content: `Professional Advisory Services for tax Consultancy in Hosur`,
  //     image: "/imgs/business/sliders/pvt.png",
  //     alt_tag: "Best Tax Advisory Consultancy service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Professional Advisory Service`,
    buyBtnLink: `#pricing-buy`,
    price: `1999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const ProAdvAboutData = {
    header: `Professional Advisory Services`,
    sub_title: `For Personal And Business Related Professional Tax Advisory Services.`,
    content_paragraph: [
      // `By providing expert consulting services in the areas of
      // finance, taxation, and law, TODAYFILINGS is able to comprehend whole
      // corporate market expansion.`,

      `From advising new business ventures entering the Indian
      market to assisting established businesses, TODAYFILINGS prioritizes
      the needs of the customer. TODAYFILINGS is a clear crystal advisory
      addressing not just current demands but also anticipated
      future needs as a result of Outstanding decades of
      involvement.`,
    ],
  };

  /* Scroll Nav Data */
  const ProAdvScrollId = [
    {
      id: `#online-consulting`,
      heading: `Online Consulting`,
    },
    {
      id: `#management-advice`,
      heading: `Financial Advice`,
    },
    {
      id: `#risk-management`,
      heading: `Risk Management`,
    },
    {
      id: `#consequences`,
      heading: `Consequences`,
    },
  ];

  /* Img Content Component Data */
  const ProAdvIcData = {
    id: 'online-consulting',
    mt_div: '',
    mt_img: '',
    header: 'Online consulting services',
    image: '/imgs/assited/live.png',
    alt_tag: 'Online consulting services',
    Paragraph: `TODAYFILINGS is capable of providing insightful advice and
    reliable solutions that meet the highest industry
    requirements. Those might be found in`,
    points: [
      {
        content: `Manage Tax & Compliance.`,
        icon: true,
      },
      {
        content: `Process for disciplining CA.`,
        icon: true,
      },
      {
        content: `Governing Interest Conflicts.`,
        icon: true,
      },

      {
        content: `Issues with management practice.`,
        icon: true,
      },

      {
        content: `Advice for "Fund Raising" Efforts.`,
        icon: true,
      },
      {
        content: `Business license and permit delivery.`,
        icon: true,
      },
      {
        content: `Planning & validation of the business.`,
        icon: true,
      },
      {
        content: `Managing partnership issues (financial).`,
        icon: true,
      },
      {
        content: `Cost accounting and financial modelling services.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ProAdvCfData = {
    id: 'management-advice',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Planning for Financial Management Advice',
    content: `Single function does not have responsibility for
    risk management. It is the Company's responsibility as
    a whole. Lack of accountability, clarity, strategy,
    and processes have led to the discovery of current
    business governance failures and scams.`,
    body: [
      {
        points: `Consistencies between businesses and the business
        environment could harm the image and sustainability of
        the brand. In order to be competitive, experts at
        Ernst & Young advise businesses to stay up to date
        with the dangers and shifting business climate (Ernst
        & Young).`,
        icon: true,
      },
      {
        head: `Customers at TODAYFILINGS are given the opportunity to
        realize their full potential`,
        points: `Evaluating operations to make sure they adhere to
        statutory directives.`,
        icon: true,
      },
      {
        points: `Giving advice for practice improvement in order to
        lower business costs & dismissals.`,
        icon: true,
      },
      {
        points: `By coordinating business processes with industry best
        practices and activating such processes, an
        organization can become a process-driven association
        rather than an association driven by humans.`,
        icon: true,
      },
      {
        points: `Development and testing of the internal financial
        controls that the business has established to protect
        against any serious fraud or misrepresentation.`,
        icon: true,
      },
      {
        points: `Grants, Both Now and Later Financial Diagram with
        Analysis of Working Capital Needs Offer the most
        advantageous cash securities.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ProAdvImages = [
    '/imgs/registration/iso/iso-img.png',
    '/imgs/registration/iso/iso-img-slide.png',
    '/imgs/registration/iso/iso-img-slider.png',
  ];

  /*  Slider Content Component Data */
  const ProAdvSCData = {
    id: 'risk-management',
    background: [],
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: 'TODAYFILINGS Risk Management Services',
    content: [
      {
        points: `Forensic facilities.`,
        icon: true,
      },
      {
        points: `TODAYFILINGS Internal audit.`,
        icon: true,
      },
      {
        points: `Sourcing workarounds.`,
        icon: true,
      },
      {
        points: `Regular business procedure.`,
        icon: true,
      },
      {
        points: `Finance internal regulation.`,
        icon: true,
      },
      {
        points: ` Start a sustainability program.`,
        icon: true,
      },

      {
        points: `Conveniences of business reorganization.`,
        icon: true,
      },
    ],
    images: ProAdvImages,
  };
  /*  Content Img Component Data */
  const ProAdvCiData = {
    id: 'consequences',
    background: 'main-content',
    mt_div: '',
    header: "COVID-19's Effects on Indian Companies",
    paragraph: '',
    points: [
      {
        content: `Many Indian companies have asked their employees to
        use video conferencing to operate from distant
        locations. Before beginning any major project, they
        are auditing their systems.`,
        icon: true,
      },
      {
        content: `The COVID-19 has a
        significant and wide-ranging impact on enterprises
        both domestically and internationally.`,
        icon: true,
      },
      {
        content: `Form a specific team with the core team members
          identified to evaluate the market and financial
          situation and develop policies.`,
        icon: true,
      },
      {
        content: `Re-examine the sales model and make any necessary
          changes with a sense of urgency to create scenarios
          for the most dire conditions.`,
        icon: true,
      },
      {
        content: `Present Cash Refund for speedier calculation Suspend
          all variable costs Bargaining the most!`,
        icon: true,
      },
      {
        content: `Decision to confront banks or lenders over late fees
          and EMIs.`,
        icon: true,
      },
      {
        content: `Suspension and maintenance are essential to
          maintaining your assets.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/shop/shop-imgslider.png',
    alt_tag: 'Consequences of COVID-19',
  };
  var FAQ_data = [
    {
      header: 'package details of professional service?',
      body: [
        {
          content: `Receive a 20-minute call from professionals with expertise.`,
          icon: true,
        },
        {
          content: `Get access to all of TODAYFILINGS's compliance services.`,
          icon: true,
        },
        {
          content: `Receive guidance on the appropriate course of action.`,
          icon: true,
        },
      ],
    },

    {
      header: 'What is Risk Management?',
      body: [
        {
          content: `Risk management is not renowned for being the responsibility of a
          single function. In terms of finances and labour, the Company as a
          whole is responsible. We at TODAYFILINGS support the current business
          governance, whose alleged failures are a result of a lack of
          responsibility, clarity, strategy, and processes.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'How can TODAYFILINGS provide professional advisory services to help its clients?',
      body: [
        {
          content: `With the help of a team of professionals, TODAYFILINGS is able to provide a
          skilled opinion in situations involving company incorporation and
          government registration services throughout India and the rest of
          the world.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is professional advisory services?',
      body: [
        {
          content: `Advisory services are part of regular business for professionals who may be individually freelancing for 
          clients, advising and managing portfolios. Many professionals may constitute themselves as a partnership 
          firm or a limited liability partnership or a corporate to provide investment advisory services.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What services are offered by advisory services?',
      body: [
        {
          content: `Advisory Services means the development and presentation of findings, conclusions, and recommendations 
          for client consideration and decision making. Advisory Services means the non-binding investment advisory services
           rendered by the Portfolio Manager to the Client.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the role of professional advisor?',
      body: [
        {
          content: `Traditionally, professional advisors such as attorneys, accountants, financial professionals, and planned giving 
          officers work apart from one another in helping accomplish a client's estate and charitable planning goals and 
          objectives.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Why do companies need advisory services?',
      body: [
        {
          content: `Business Advisory Services provide the necessary experience to address a wide range of business issues and advise
           businesses on how to make the necessary modifications to boost productivity, reduce taxes, and coordinate activities. More importantly, they also provide the point of view from the outside.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Professional Advisory Services'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ProAdvSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ProAdvAboutData} />

          <Counter />

          <ScrollNav scroll_data={ProAdvScrollId} />

          <ImgContent item={ProAdvIcData} />

          <ContentForm CFSection_data={ProAdvCfData} />

          <SliderContent ScSection_data={ProAdvSCData} />

          <ContentImg CISection_data={ProAdvCiData} />
          <Guidance />
          <Cta />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
